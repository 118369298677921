import React from 'react'
import styled, { css } from 'styled-components'
import { colors, fonts, mediaQueries, spacing } from '../../tokens'

type InputProps = React.InputHTMLAttributes<HTMLInputElement>

interface TProps extends Omit<InputProps, 'onChange'> {
  /** The label tag for the input. */
  label: string
  /** The name tag for the input. */
  name: string
  /** If element show inline. */
  inline?: boolean
  /** If value is required. */
  required?: boolean
  /** If input is only read. */
  readOnly?: boolean
  /** The type tag for the input. */
  type?: 'text' | 'password' | 'email' | 'number'
  /** The value for the input. */
  value?: string | number
  /** The min value when input is number */
  min?: string
  /** The max value when input is number */
  max?: string
  /** The value for the placeholder. */
  placeholder?: string
  /** Handler to be called when value is change */
  onChange?(value: string, id: string): void
  /** The error for the input. */
  error?: string | null
}

const TextInput: React.FC<TProps> = ({
  label,
  name,
  inline = false,
  required,
  readOnly = false,
  type = 'text',
  value = '',
  placeholder = '',
  onChange,
  error,
  ...rest
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event.currentTarget.value, name)
  }
  return (
    <Wrapper inline={inline}>
      {label && (
        <Label inline={inline} htmlFor={name}>
          {label}
          {required && '*'}
        </Label>
      )}
      <div>
        <Input
          id={name}
          name={name}
          required={required}
          inline={inline}
          readOnly={readOnly}
          type={type}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          {...rest}
        />
        {error && <Error>{error}</Error>}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ inline?: boolean }>`
  margin-bottom: ${props => (props.inline ? '0px' : spacing.small)};
  text-align: left;
  ${props =>
    props.inline &&
    css`
      align-items: center;
      display: flex;
      @media ${mediaQueries.smallMax} {
        flex-direction: column;
      }
    `}
`

const Label = styled.label<{ inline?: boolean }>`
  color: ${colors.base.gray};
  font-size: ${fonts.size.default};
  font-family: ${fonts.family.default};
  ${props =>
    props.inline &&
    css`
      margin-right: ${spacing.small};
    `}
`

const Input = styled.input<{ inline?: boolean }>`
  ${props =>
    props.readOnly &&
    css`
      background-color: ${colors.base.grayLightest};
    `}
  border: solid 1px ${colors.base.grayLightest};
  border-radius: 8px;
  box-sizing: border-box;
  color: ${colors.base.gray};
  font-size: ${fonts.size.default};
  font-family: ${fonts.family.default};
  margin-top: ${props => (props.inline ? '0px' : spacing.xxsmall)};
  padding: ${spacing.xsmall} ${spacing.xsmall};
  transition: border-color 0.25s, box-shadow 0.25s;
  width: 100%;
  &:hover {
    border-color: ${colors.base.grayLight};
  }
  &:focus {
    border-color: ${colors.base.purple};
    box-shadow: 0px 0px 0px 1px ${colors.base.grayLightest};
    outline: none;
  }
  &::placeholder {
    font-size: ${fonts.size.small};
    font-family: ${fonts.family.default};
  }
`

const Error = styled.div`
  color: ${colors.base.red};
  font-size: ${fonts.size.small};
  margin-top: ${spacing.xxsmall};
`

export default TextInput
