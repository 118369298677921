import React from 'react'
import Page from '../components/Page'
import styled from 'styled-components'
import { spacing } from '../tokens'

export default function TerminosYCondiciones() {
  return (
    <Page hasFooter={false}>
      <Container>
        <div>
          <h1>Términos de servicio de Alau.org</h1>
        </div>
        <p>
          Bienvenido a Alau.org (la “Compañía”, “nosotros” o “nos”). Nos
          complace en brindarle el acceso al servicio, sujeto a estos términos y
          condiciones. Al acceder y usar este servicio, usted expresa su
          consentimiento, acuerdo y entendimiento de los Términos de Servicio.
          Si no está de acuerdo con los Términos de Servicio no utilice el
          Servicio.
        </p>

        <p>
          Al aceptar estos Términos de Servicio, se le concede a usted un
          derecho ilimitado hasta el periodo de presentación del examen
          especificada en la factura como el periodo de presentación del examen,
          para entrar, acceder y utilizar el Servicio exclusivamente para uso
          personal y no comercial. Usted acepta que este permiso es para su uso
          personal, no comercial e intransferible y que nadie más que usted va a
          utilizar el Servicio. Usted no tiene derechos para copiar o reproducir
          en todo o en parte alguna porción del Servicio.
        </p>

        <h4>Servicio</h4>

        <p>
          El servicio se rige a la capacitación en modalidad 100% online en las
          asignaturas de Matemáticas, Lengua y Literatura, Ciencias Naturales y
          Ciencias Sociales.
        </p>

        <h4>Terminación</h4>

        <p>
          La Compañía se reserva el derecho a dar por terminada su Cuenta de
          Alau.org y/o el acceso por parte de usted al Servicio si infringe los
          Términos de Servicio. Si se diera por terminada su inscripción de
          Alau.org, no será reembolsable el pago de la inscripción.
        </p>

        <p>
          Queda totalmente prohibida la descarga y distribución total o parcial
          ya sea por medios online o físicos del contenido, en caso de infringir
          esta cláusula se procederá con la suspensión inmediata de la cuenta y
          se tomarán las medidas legales correspondientes.
        </p>

        <h4>El derecho de la Compañía a cambiar estos Términos de Servicio.</h4>

        <p>
          La Compañía se reserva el derecho, a su discreción, de cambiar,
          modificar, añadir o eliminar partes de estos Términos de Servicio en
          cualquier momento, sin previo aviso a usted. Todos los cambios
          entrarán en vigor de inmediato. En caso de algún cambio sustancial,
          haremos todos los esfuerzos comercialmente razonables para
          notificárselo y obtener su consentimiento antes de implementar dichos
          cambios. Le recomendamos que consulte estos Términos de Servicio en
          forma periódica para ver si se han registrado cambios. El uso
          continuado del Servicio por parte suya después de la publicación de
          dichos cambios implica la aceptación de los mismos.
        </p>

        <h4>Enlaces a páginas web de terceros y anunciantes</h4>

        <p>
          Nuestra Página Web podrá contener hipervínculos a páginas web de
          terceros que recojan y divulguen datos de manera distinta a la de
          nuestra Página Web. La Compañía no será responsable de la recogida,
          utilización o divulgación de los datos recogidos a través de dichas
          páginas web de terceros, y declinamos expresamente cualquier
          responsabilidad relacionada con dicha recogida, utilización, o
          divulgación. Le recomendamos que lea las políticas de privacidad de
          las demás páginas web que usted visite. Adicionalmente, otras
          compañías que inserten anuncios en nuestra Página Web podrán recoger
          datos acerca de usted mediante la utilización de cookies cuando
          visualice o haga clic en sus anuncios. No podremos controlar la
          mencionada recogida de datos. Deberá contactar directamente con estos
          anunciantes si tiene cualquier duda acerca de la utilización de los
          datos recogidos.
        </p>

        <h4>Política de reembolso</h4>

        <p>
          Podemos hacerle un reembolso del 100% del valor de su inscripción
          durante las 48 horas después de contratado el Servicio. Siempre y
          cuando usted no presente ningún tipo de actividad dentro de la
          Plataforma de Capacitación. Podemos hacerle un reembolso del 40% del
          valor de su inscripción durante los 4 días posteriores a la
          contratación del Servicio. Después de esto no realizamos devoluciones.
        </p>

        <h4>Derecho al cambio de precios.</h4>

        <p>
          Todos los precios relacionados con el Servicio están sujetos a cambios
          por la Compañía en cualquier momento, sin previo aviso y sin
          responsabilidad alguna hacia usted. La Compañía no ofrece una
          protección de precios o rembolsos en caso de una caída de precios o
          una oferta promocional.
        </p>

        <h4>Derecho al cambio y/o actualización del Servicio</h4>

        <p>
          Nos reservamos el derecho a realizar actualizaciones periódicas del
          Servicio, con o sin previo aviso.
        </p>

        <h4>Riesgo de uso.</h4>

        <p>
          La Compañía no asumirá responsabilidad alguna y no será responsable
          por ningún daño o virus que provoque algún daño a su computadora u
          otra propiedad como consecuencia del acceso, uso, descarga o
          navegación por el Servicio.
        </p>

        <h4>Uso de Información Personal</h4>

        <p>
          No venderemos, comercializaremos, alquilaremos o divulgaremos sus
          datos a ningún tercero.
        </p>

        <h4>Comunidades, foros, salas de chat y tablones de anuncios</h4>

        <p>
          Si opta por revelar Datos de Carácter Personal en comunidades,
          tablones de anuncios, foros y espacios similares a los que pueda
          accederse a través de la Página Web, le informamos de que tanto dichos
          datos como los que usted divulgue en su comunicación podrán ser
          recogidos y utilizados por terceros y dar lugar al envío de mensajes
          no solicitados de terceros. Para solicitar que eliminemos sus datos
          personales de nuestro blog o foro, póngase en contacto con nosotros
          enviando un correo electrónico a info@alau.org Usted reconoce y acepta
          que los comentarios en salas de chat u otras áreas públicas de la
          Página Web podrán ser visualizados por terceros. Si no desea que sus
          comentarios sean visualizados por terceros, le recomendamos que no
          haga ningún comentario. Usted será enteramente responsable, en última
          instancia, de toda actividad que realice en comunidades, foros,
          tablones de anuncios, salas de chat, etc. Actúe con precaución y
          responsabilidad cuando se conecte a Internet.
        </p>

        <h4>Secretos comerciales y materiales de propiedad exclusiva.</h4>

        <p>
          El Servicio contiene y/o incorpora secretos comerciales, inventos
          patentados (y/o con patente pendiente) y / u otros materiales de
          propiedad exclusiva y propiedad intelectual de la Compañía y/o sus
          licenciadores. Todos los derechos de título y propiedad en el
          Servicio, incluyendo sin que signifique limitación alguna el contenido
          Alau.org y cualquier copia relacionada con el Servicio y el contenido
          de Alau.org, permanecen bajo la propiedad de la Compañía y sus
          licenciadores, según corresponda. Usted conviene en mantener en forma
          confidencial aquella información contenida en el Servicio que no sea
          de dominio público, y no revelar dicha información a terceros sin la
          aprobación previa por escrito de la Compañía.
        </p>

        <h4>Derechos de autor.</h4>

        <p>
          El Contenido de Alau.org y otras obras de autoría que se encuentran en
          el Servicio, o forman parte del mismo (colectivamente, el
          "Contenido"), están protegidos por las leyes aplicables de derechos de
          autor del Instituto Ecuatoriano de Propiedad Intelectual y los
          tratados internacionales de derechos de autor, así como por otras
          leyes y tratados de propiedad intelectual. El Contenido se licencia,
          no se vende. Usted no puede realizar copias no autorizadas o utilizar
          alguna parte del Contenido, salvo como se especifica en este documento
          y de conformidad con la legislación aplicable. Todos los títulos y
          derechos de autor del Contenido y del Servicio (incluyendo, entre
          otros, imágenes, fotografías, animaciones, vídeo, audio, código,
          artículos y texto, incorporados al Servicio), son propiedad de la
          Compañía o sus licenciadores. Usted acepta cumplir con todas las leyes
          aplicables sobre protección de derechos de autor en relación con el
          uso del Servicio y del Contenido por parte de usted. Además de lo
          estipulado en estos Términos de Servicio, la Compañía podrá adoptar
          medidas adecuadas para proteger los derechos de autor en el Contenido.
        </p>
      </Container>
    </Page>
  )
}

const Container = styled.div`
  margin-bottom: ${spacing.large};
  padding: ${spacing.medium};
  h1 {
    line-height: ${spacing.large};
  }
  p {
    margin-top: ${spacing.small};
  }
`
