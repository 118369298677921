import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useAsync } from 'react-async'
import DatePicker, { registerLocale } from 'react-datepicker'
import es from 'date-fns/locale/es'

import { courseRequirementsOfUser } from '../../../lib/api'
import { useNotification } from '../../../context/notificationContext'
import Button from '../../../components/Button'
import Loading from '../../Loading'
import Select from '../../../components/Select'
import TextInput from '../../../components/TextInput'
import Link from '../../../components/Link'
import Text from '../../../components/Text'
import { colors, fonts, mediaQueries, spacing } from '../../../tokens'
import { useUser } from '../../../context/userContext'
import { requirementsSchema, CountriesEnum } from '../../../util/validations'
import 'react-datepicker/dist/react-datepicker.css'
import './datepicker.css'
import provinciasFromJson from '../data/provincias.json'
import etniasFromjson from '../data/etnia.json'
import instruccionLevelFromJson from '../data/instruccion.json'
import occupationFromjson from '../data/ocupacion.json'
import actividadEconomicaFromjson from '../data/actividadEconomica.json'

registerLocale('es', es)

interface Props {
  course: string
  onCompleted(): void
}

interface LocalizationType {
  _id: string
  nombre: string
}

interface OptionType {
  value: string
  label: string
}

interface User {
  documentoIdentificacion: string
  nombre: string
  email: string
  pais: LocalizationType | null
  provincia: LocalizationType | null
  ciudad: LocalizationType | null
  sexo: OptionType | null
  telefono: string
  edad: string
  // fechaNacimiento: any
  etnia: OptionType | null
  nivelInstruccion: OptionType | null
  ocupacion: OptionType | null
  actividadEconomica: OptionType | null
  terms: boolean
}

interface OtherLocalization {
  otherCountry: string
  otherCity: string
}

interface ErrorsType {
  [propName: string]: string
}

const countries = [
  { _id: CountriesEnum.ECUADOR, nombre: CountriesEnum.ECUADOR },
  { _id: CountriesEnum.OTRO, nombre: CountriesEnum.OTRO },
]

const genres = [
  { value: 'femenino', label: 'Femenino' },
  { value: 'masculino', label: 'Masculino' },
  { value: 'otro', label: 'Otro' },
]

function FormEF({ course, onCompleted }: Props) {
  const { notify } = useNotification()
  const { user } = useUser()

  const [errors, setErrors] = useState<ErrorsType | null>(null)

  const [userInfo, setUserInfo] = useState<User>({
    documentoIdentificacion: '',
    nombre: user.nombre ? user.nombre : '',
    email: user.email ? user.email : '',
    pais: null,
    provincia: null,
    ciudad: null,
    sexo: null,
    telefono: '',
    // fechaNacimiento: '',
    edad: '',
    etnia: null,
    nivelInstruccion: null,
    ocupacion: null,
    actividadEconomica: null,
    terms: false,
  })

  const [provincesJson] = useState<Record<string, string[]>>(provinciasFromJson)
  const [provinces, setProvinces] = useState<LocalizationType[]>([])
  const [cities, setCities] = useState<LocalizationType[]>([])
  const [ethnicities, setEthnicities] = useState<OptionType[]>([])
  const [instruccionLevel, setInstruccionLevel] = useState<OptionType[]>([])
  const [occupationLevel, setOccupationLevel] = useState<OptionType[]>([])
  const [actividadEconomica, setActividadEconomica] = useState<OptionType[]>([])
  const [otherLocalization, setOtherLocalization] = useState<OtherLocalization>(
    {
      otherCountry: '',
      otherCity: '',
    },
  )

  useEffect(() => {
    setProvinces(
      Object.keys(provincesJson).map(province => {
        return { _id: province, nombre: province }
      }),
    )

    setEthnicities(
      etniasFromjson.map(ethnicitie => {
        return { value: ethnicitie, label: ethnicitie }
      }),
    )

    setInstruccionLevel(
      instruccionLevelFromJson.map(instruccionLevel => {
        return { value: instruccionLevel, label: instruccionLevel }
      }),
    )

    setOccupationLevel(
      occupationFromjson.map(occupation => {
        return { value: occupation, label: occupation }
      }),
    )
  }, [])

  useEffect(() => {
    const provinceCities = provincesJson[`${userInfo.provincia?._id}`]
    if (provinceCities) {
      setCities(
        provinceCities.map(city => {
          return { _id: city, nombre: city }
        }),
      )
    } else {
      setCities([])
    }
  }, [userInfo.provincia])

  useEffect(() => {
    if (userInfo.ocupacion?.value === 'Independiente / emprendedor') {
      setActividadEconomica(
        actividadEconomicaFromjson.map(actividad => {
          return { value: actividad, label: actividad }
        }),
      )
    }
  }, [userInfo.ocupacion])

  function handleChangeCheckbox(event: React.ChangeEvent<HTMLInputElement>) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    setUserInfo({
      ...userInfo,
      [name]: value,
    })
  }

  function handleChangeInput(value: string, id: string) {
    setUserInfo({
      ...userInfo,
      [id]: value,
    })
  }

  function handleOtherLocalization(value: string, id: string) {
    setOtherLocalization({
      ...otherLocalization,
      [id]: value,
    })
  }

  function handleChangeSelectOption(selectedOption: any, action: any) {
    setUserInfo({
      ...userInfo,
      [`${action.name}`]: selectedOption,
    })
  }

  function handleLocalization(selectedOption: LocalizationType, action: any) {
    if (action.name === 'pais') {
      setUserInfo({
        ...userInfo,
        ciudad: null,
        provincia: null,
        pais: selectedOption,
      })
    } else if (action.name === 'provincia') {
      setUserInfo({
        ...userInfo,
        ciudad: null,
        provincia: selectedOption,
      })
    } else {
      setUserInfo({
        ...userInfo,
        ciudad: selectedOption,
      })
    }
  }

  function isOtherCountry() {
    return userInfo.pais && userInfo.pais._id === CountriesEnum.OTRO
  }

  const sendRequeriments = useAsync({
    deferFn: args => courseRequirementsOfUser(args[0], course),
    onReject: error => notify(error.message, 'error'),
    onResolve: () => {
      onCompleted()
    },
  })

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    let localization = {}
    if (isOtherCountry()) {
      localization = {
        pais: otherLocalization.otherCountry,
        provincia: null,
        ciudad: otherLocalization.otherCity,
      }
    } else {
      localization = {
        pais: userInfo && userInfo.pais ? userInfo.pais.nombre : null,
        provincia:
          userInfo && userInfo.provincia ? userInfo.provincia.nombre : null,
        ciudad: userInfo && userInfo.ciudad ? userInfo.ciudad.nombre : null,
      }
    }

    const body = {
      ...userInfo,
      edad: Number(userInfo.edad),
      sexo: userInfo && userInfo.sexo ? userInfo.sexo.value : null,
      etnia: userInfo && userInfo.etnia ? userInfo.etnia.value : null,
      nivelInstruccion:
        userInfo && userInfo.nivelInstruccion
          ? userInfo.nivelInstruccion.value
          : null,
      ocupacion:
        userInfo && userInfo.ocupacion ? userInfo.ocupacion.value : null,
      actividadEconomica:
        userInfo && userInfo.actividadEconomica
          ? userInfo.actividadEconomica.value
          : '',
      ...localization,
      terms: userInfo.terms,
    }

    requirementsSchema
      .validate(body, { abortEarly: false })
      .then(function() {
        sendRequeriments.run(body)
      })
      .catch(function(err) {
        const validationErrors: ErrorsType = {}
        if (err.inner) {
          err.inner.forEach((error: any) => {
            if (error.path) {
              validationErrors[error.path] = error.message
            }
          })
        }
        setErrors(validationErrors)
      })
  }

  if (sendRequeriments.isLoading) {
    return <Loading />
  }

  return (
    <Form onSubmit={handleSubmit}>
      <TextInput
        label="Nombre Completo"
        name="nombre"
        onChange={handleChangeInput}
        value={userInfo.nombre}
        error={errors && errors.nombre}
      />
      <TextInput
        label="Email"
        name="email"
        onChange={handleChangeInput}
        value={userInfo.email}
        readOnly={user.email ? true : false}
        error={errors && errors.email}
      />
      <TextInput
        label="Documento de identificación"
        name="documentoIdentificacion"
        onChange={handleChangeInput}
        value={userInfo.documentoIdentificacion}
        error={errors && errors.documentoIdentificacion}
      />
      <TextInput
        label="Número de celular"
        name="telefono"
        onChange={handleChangeInput}
        type="text"
        value={userInfo.telefono}
        error={errors && errors.telefono}
      />
      <TextInput
        label="Edad"
        name="edad"
        onChange={handleChangeInput}
        type="number"
        value={userInfo.edad}
        error={errors && errors.edad}
        // min="0"
        // max="100"
      />
      <Select
        value={userInfo.sexo}
        label="Género"
        name="sexo"
        options={genres}
        onChange={handleChangeSelectOption}
        isSearchable={false}
        error={errors && errors.sexo}
      />
      {countries ? (
        <Select
          value={userInfo.pais as LocalizationType}
          label="País"
          name="pais"
          options={countries}
          onChange={handleLocalization}
          optionValue={'_id'}
          optionLabel={'nombre'}
          error={errors && errors['pais']}
        />
      ) : null}
      {userInfo.pais?._id === CountriesEnum.ECUADOR && provinces ? (
        <Select
          value={userInfo.provincia as LocalizationType}
          label="Provincia"
          name="provincia"
          options={provinces}
          onChange={handleLocalization}
          optionValue={'_id'}
          optionLabel={'nombre'}
          error={errors && errors['provincia']}
        />
      ) : null}
      {userInfo.pais?._id === CountriesEnum.ECUADOR && provinces.length ? (
        <Select
          value={userInfo.ciudad as LocalizationType}
          label="Ciudad"
          name="ciudad"
          options={cities}
          onChange={handleLocalization}
          optionValue={'_id'}
          optionLabel={'nombre'}
          error={errors && errors['ciudad']}
        />
      ) : null}
      {isOtherCountry() ? (
        <WrapperTwoColumns>
          <div>
            <TextInput
              label="Ingresa tu País"
              name="otherCountry"
              onChange={handleOtherLocalization}
              value={otherLocalization.otherCountry}
              error={errors && errors['pais']}
            />
          </div>
          <div>
            <TextInput
              label="Ingresa tu Ciudad"
              name="otherCity"
              onChange={handleOtherLocalization}
              value={otherLocalization.otherCity}
              error={errors && errors['ciudad']}
            />
          </div>
        </WrapperTwoColumns>
      ) : null}
      <Select
        value={userInfo.etnia as OptionType}
        label="Etnia"
        name="etnia"
        options={ethnicities}
        onChange={handleChangeSelectOption}
        isSearchable={false}
        error={errors && errors.etnia}
      />
      <Select
        value={userInfo.nivelInstruccion as OptionType}
        label="Nivel de Instruccion"
        name="nivelInstruccion"
        options={instruccionLevel}
        onChange={handleChangeSelectOption}
        isSearchable={false}
        error={errors && errors.nivelInstruccion}
      />
      <Select
        value={userInfo.ocupacion as OptionType}
        label="Ocupación"
        name="ocupacion"
        options={occupationLevel}
        onChange={handleChangeSelectOption}
        isSearchable={false}
        error={errors && errors.ocupacion}
      />
      {userInfo.ocupacion?.value === 'Independiente / emprendedor' ? (
        <Select
          value={userInfo.actividadEconomica as OptionType}
          label="Seleccione la actividad económica en la cuál usted se desenvuelve"
          name="actividadEconomica"
          options={actividadEconomica}
          onChange={handleChangeSelectOption}
          isSearchable={false}
          error={errors && errors.actividadEconomica}
        />
      ) : null}
      <CheckboxContainer>
        <label className="checkbox">
          Acepto los terminos y condiciones
          <input
            type="checkbox"
            name="terms"
            onChange={handleChangeCheckbox}
            checked={userInfo.terms}
          />
          <span className="checkmark" />
        </label>
        {errors && errors.terms && <Error>{errors.terms}</Error>}
      </CheckboxContainer>
      <TermsContainer>
        <Text>Revisa los términos y condiciones </Text>
        <Link href="/terminos-y-condiciones-inscripcion">aquí</Link>
      </TermsContainer>
      <WrapperButton>
        <Button type="submit">Continuar</Button>
      </WrapperButton>
      {errors && (
        <Error style={{ textAlign: 'center' }}>
          El formulario contiene errores, por favor revisa que toda la
          información esté correcta
        </Error>
      )}
    </Form>
  )
}

const Form = styled.form`
  @media ${mediaQueries.mediumMin} {
    margin: 0 auto;
    width: 75%;
  }
`

const Label = styled.label`
  color: ${colors.base.gray};
  font-size: ${fonts.size.default};
  font-family: ${fonts.family.default};
`

const WrapperButton = styled.div`
  text-align: center;
  margin-top: 30px;
`

const WrapperTwoColumns = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    width: 100%;
    &:first-child {
      margin-right: ${spacing.small};
    }
  }
  @media ${mediaQueries.smallMax} {
    flex-direction: column;
  }
`

const Error = styled.div`
  color: ${colors.base.red};
  font-size: ${fonts.size.small};
  margin: ${spacing.xxsmall} 0px ${spacing.small};
`

const DatePickerCustomInput = styled(DatePicker)`
  border: solid 1px ${colors.base.grayLightest};
  border-radius: 8px;
  box-sizing: border-box;
  color: ${colors.base.gray};
  font-size: ${fonts.size.default};
  font-family: ${fonts.family.default};
  margin-top: ${spacing.xxsmall};
  padding: ${spacing.xsmall};
  transition: border-color 0.25s, box-shadow 0.25s;
  width: 100%;
  &:hover {
    border-color: ${colors.base.grayLight};
  }
  &:focus {
    border-color: ${colors.base.purple};
    box-shadow: 0px 0px 0px 1px ${colors.base.grayLightest};
    outline: none;
  }
  &::placeholder {
    font-size: ${fonts.size.small};
    font-family: ${fonts.family.default};
  }
`

const WrapperInputAndLabel = styled.div`
  margin-bottom: ${spacing.small};
  text-align: left;
`

const TermsContainer = styled.div`
  text-align: center;
`

const CheckboxContainer = styled.div`
  margin-top: ${spacing.xlarge};
  text-align: center;
  /* Estilos para el elemento checkbox */
  .checkbox {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    padding-right: 20px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: ${colors.base.white};
    border: 1px solid ${colors.base.grayLightest};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* When the checkbox is checked, add background */
  .checkbox input:checked ~ .checkmark {
    background-color: ${colors.base.white};
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .checkbox input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkbox .checkmark:after {
    width: 6px;
    height: 12px;
    background-color: ${colors.base.white};
    border: solid ${colors.base.purple};
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`

export default FormEF
