import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useAsync } from 'react-async'

import Card from '../../../../components/Card'
import Heading from '../../../../components/Heading'
import Loading from '../../../Loading'
import Modal from '../../../../components/Modal'
import Text from '../../../../components/Text'
import QuizLesson from '../QuizLesson'
import VideoLesson from '../VideoLesson'
import TextOrLinkLesson from '../TextOrLinkLesson'
import VideoModal from '../VideoModal'
import PracticeLesson from '../PracticeLesson'
import { mediaQueries, spacing } from '../../../../tokens'
import { useUser } from '../../../../context/userContext'
import { useNotification } from '../../../../context/notificationContext'
import {
  Lesson,
  Practice,
  CourseWithContents,
  ContentWithLessons,
} from '../../../../lib/formatApiObjects'
import { markLessonAsCompleted } from '../../../../lib/api'
import { ADD_PROGRESS } from '../../reducers/courseReducer'
import { LESSON_COMPLETED } from '../../reducers/contentReducer'
import {
  useCourseContext,
  useContentContext,
} from '../../context/CourseProvider'
import userIsProInCourse from '../../../../util/userIsProInCourse'

interface ContentDetail {
  _id: string
  title: string
  lessons: Lesson[]
  practice: Practice
}

function CourseContent() {
  const history = useHistory()
  const { user } = useUser()
  const { notify } = useNotification()
  const { courseWithContents, dispatchCourse } = useCourseContext() as {
    courseWithContents: CourseWithContents
    dispatchCourse: any
  }
  const { contentWithLessons, dispatchContent } = useContentContext() as {
    contentWithLessons: ContentWithLessons
    dispatchContent: any
  }
  const [currentLesson, setCurrentLesson] = useState<Lesson | null>(null)
  const markLesson = useAsync({
    deferFn: args => markLessonAsCompleted(args[0], args[1]),
    onReject: error => notify(error.message, 'error'),
  })

  const { isFetching } = contentWithLessons
  if (isFetching) {
    return <Loading />
  }

  if (!contentWithLessons.lessons || !contentWithLessons.lessons.length) {
    return <Text>Lo sentimos no existe contenido</Text>
  }

  function openLesson(lesson: Lesson) {
    if (lesson.category === 'link' || lesson.category === 'requirement') {
      if (lesson.link.startsWith('/')) {
        history.push(lesson.link)
      } else {
        window.open(lesson.link)
      }
    } else {
      setCurrentLesson(lesson)
    }
  }

  function completeLesson(lesson: Lesson) {
    markLesson.run(courseWithContents.id, lesson._id)
    if (!lesson.completed && lesson.isPonderable) {
      dispatchCourse({
        type: ADD_PROGRESS,
        payload: contentWithLessons._id,
      })
      dispatchContent({
        type: LESSON_COMPLETED,
        payload: lesson._id,
      })
    }
  }

  const { lessons, practice } = contentWithLessons

  function handleClickVideo(index: number) {
    const lesson = lessons[index]
    openLesson(lesson)
    completeLesson(lesson)
  }

  function handleClickQuiz(slug: string) {
    history.push(`/quiz/${slug}`)
  }

  function handleClickPractice() {
    if (contentWithLessons) {
      history.push(
        `/simulador/${courseWithContents.slug}/${contentWithLessons._id}`,
      )
    }
  }

  function handleRestrictContent() {
    if (courseWithContents.urlWebSite) {
      window.open(courseWithContents.urlWebSite)
    } else {
      window.open('https://cursos.alau.org/')
    }
  }

  function handleCloseModal() {
    setCurrentLesson(null)
  }

  const practiceCard = practice.enabled ? (
    <WrapperCard key="practice">
      <Card>
        <Card.Body paddingSize="none">
          <PracticeLesson
            questions={practice.questions}
            startAt={practice.startAt}
            correct={practice.correct}
            onClick={handleClickPractice}
          />
        </Card.Body>
      </Card>
    </WrapperCard>
  ) : null

  const isProInCourse = userIsProInCourse(user.cursos, courseWithContents.id)

  return (
    <Wrapper>
      <HeadingWrapper>
        <Heading size={2}>{contentWithLessons.title}</Heading>
      </HeadingWrapper>
      {lessons.map((lesson: Lesson, index: number) => {
        const { quiz } = lesson
        const lessonCard = (
          <WrapperCard key={lesson._id}>
            <Card>
              <Card.Body paddingSize="none">
                {lesson.category === 'video' && (
                  <VideoLesson
                    image={lesson.image}
                    duration={lesson.duration}
                    name={lesson.name}
                    completed={lesson.completed}
                    isAccessPro={!lesson.free}
                    userIsPro={isProInCourse}
                    isPonderable={lesson.isPonderable}
                    onClick={() => handleClickVideo(index)}
                    onRestrictContent={handleRestrictContent}
                  />
                )}
                {lesson.category === 'quiz' && (
                  <QuizLesson
                    name={quiz.name}
                    slug={quiz.slug}
                    duration={quiz.duration}
                    questions={quiz.questions}
                    startAt={quiz.startAt}
                    correct={quiz.correct}
                    total={quiz.total}
                    isAccessPro={quiz.pro}
                    approved={quiz.approved}
                    gradeToApproved={quiz.gradeToApproved}
                    isPonderable={lesson.isPonderable}
                    userIsPro={isProInCourse}
                    onClick={handleClickQuiz}
                    onRestrictContent={handleRestrictContent}
                  />
                )}
                {(lesson.category === 'text' ||
                  lesson.category === 'link' ||
                  lesson.category === 'requirement') && (
                  <TextOrLinkLesson
                    image={lesson.image}
                    name={lesson.name}
                    resumen={lesson.resumen}
                    completed={lesson.completed}
                    isAccessPro={!lesson.free}
                    userIsPro={isProInCourse}
                    isPonderable={lesson.isPonderable}
                    category={lesson.category}
                    filledRequirements={courseWithContents.filledRequirements}
                    onClick={() => handleClickVideo(index)}
                    onRestrictContent={handleRestrictContent}
                  />
                )}
              </Card.Body>
            </Card>
          </WrapperCard>
        )

        return index === lessons.length - 1 && lesson.category === 'quiz' ? (
          <React.Fragment key={lesson._id}>
            {practiceCard}
            {lessonCard}
          </React.Fragment>
        ) : (
          lessonCard
        )
      })}

      {lessons[lessons.length - 1].category !== 'quiz' ? practiceCard : null}

      {currentLesson && (
        <Modal onClose={handleCloseModal} hasBackground={false}>
          <VideoModal
            name={currentLesson.name}
            description={currentLesson.description}
            url={currentLesson.url}
            category={currentLesson.category}
          />
        </Modal>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: ${spacing.small};
  @media ${mediaQueries.largeMin} {
    margin-top: ${spacing.xlarge};
  }
`

const HeadingWrapper = styled.div`
  display: block;
  padding-bottom: ${spacing.medium};
  @media ${mediaQueries.largeMin} {
    display: none;
  }
`

const WrapperCard = styled.div`
  margin-bottom: ${spacing.xlarge};
`

export default CourseContent
