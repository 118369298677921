import React from 'react'
import Page from '../components/Page'
import styled from 'styled-components'
import { spacing } from '../tokens'

export default function TerminosYCondicionesEF() {
  return (
    <Page hasFooter={false}>
      <Container>
        <div>
          <h1>Autorización para uso de tratamiento de datos personales</h1>
        </div>

        <p>
          Le informamos que el tratamiento de los datos personales que nos
          comparta y se recopilen por la COOPERATIVA DE AHORRO Y CREDITO JARDIN
          AZUAYO LTDA. (en adelante la COOPERATIVA) en virtud de los programas
          “EDUCOOPE” se rigen por los siguientes términos:
        </p>

        <p>
          · <strong>Responsable:</strong> COOPERATIVA DE AHORRO Y CREDITO JARDIN
          AZUAYO LTDA.
        </p>

        <p>
          · <strong>Datos personales:</strong> nombres, apellidos, número de
          cédula, dirección de correo electrónico, crediticios.
        </p>

        <p>
          · <strong>Finalidades:</strong> atender y gestionar su postulación ya
          sea de manera virtual o presencial, enviar publicidad de las ofertas
          académicas, cursos de educación continua, oferta de nuestros servicios
          y gestionar su posible candidatura para elecciones de Gobierno
          Corporativo o futuros colaboradores.
        </p>

        <p>
          · <strong>Base legal:</strong> cumplimiento de obligaciones
          contractuales, interés legítimo de la COOPERATIVA y, cuando
          corresponda, su consentimiento.
        </p>

        <p>
          · <strong>Acceso:</strong> la COOPERATIVA no comunicará sus datos
          personales a terceros, salvo cuando sea estrictamente necesario para
          gestionar su postulación y, en su caso, mantener la relación con la
          COOPERATIVA. En particular, sólo tendrán acceso a los datos personales
          aquellos terceros a los que la COOPERATIVA haya encomendado una
          prestación de servicios de soporte técnico e informático, y, en su
          caso, la remisión de comunicaciones electrónicas. Asimismo, la
          COOPERATIVA podrá comunicar sus datos a sucursales y filiales, e
          igualmente para efectos de almacenar información en bases de datos
          locales y/o extranjeras. En caso de que sea necesario y para cumplir
          con obligaciones contractuales, la COOPERATIVA podrá transferir
          internacionalmente sus datos personales. Para el efecto, la
          COOPERATIVA adoptará las medidas técnicas y organizativas aplicables
          para cumplir con la confidencialidad y seguridad correspondientes.
        </p>

        <p>
          · <strong>Conservación:</strong> Los datos personales se conservarán
          mientras se mantenga la relación contractual con usted, y sea
          necesario para cumplir con los objetivos para los cuales fue
          recolectada la información o durante el tiempo necesario para cumplir
          con las obligaciones legales y atender las posibles responsabilidades
          que pudieran derivar del cumplimiento de la finalidad para la cual los
          datos fueron recabados.
        </p>

        <p>
          · <strong>Derechos:</strong> Puede retirar su consentimiento en
          cualquier momento, así como ejercer sus derechos de acceso,
          eliminación, rectificación y actualización, oposición, suspensión,
          portabilidad, y, a no ser objeto de una decisión basada única o
          parcialmente en valoraciones automatizadas. Para más información,
          puede dirigir su consulta a la dirección de correo electrónico
          comunicacion@jardinazuayo.fin.ec
        </p>

        <p>
          Declaro que la información proporcionada a la COOPERATIVA es veraz,
          por lo que eximo a la misma de los resultados del procesamiento de
          información que se realicen como consecuencia de datos erróneos,
          desactualizados o inexactos.
        </p>

        <p>
          Será obligatorio que nos facilite sus datos en la medida en que usted
          participe en los programas “EDUCOOPE”, debiendo mantenerlos
          actualizados, de forma que responda en todo momento a su situación
          real. La COOPERATIVA se reserva el derecho a no prestar los servicios
          correspondientes en caso de que se proporcione datos erróneos,
          desactualizados o inexactos.
        </p>

        <p>
          Será obligatorio que nos facilite sus datos en la medida en que usted
          participe en los programas “EDUCOOPE”, debiendo mantenerlos
          actualizados, de forma que responda en todo momento a su situación
          real. La COOPERATIVA se reserva el derecho a no prestar los servicios
          correspondientes en caso de que se proporcione datos erróneos,
          desactualizados o inexactos.
        </p>

        <p>
          He leído y autorizo a la COOPERATIVA a realizar el tratamiento de mis
          datos personales en los términos antes anunciados.
        </p>
      </Container>
    </Page>
  )
}

const Container = styled.div`
  margin-bottom: ${spacing.large};
  padding: ${spacing.medium};
  h1 {
    line-height: ${spacing.large};
  }
  p {
    margin-top: ${spacing.small};
  }
`
