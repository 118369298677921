import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router'
import styled from 'styled-components'
import { useAsync } from 'react-async'
import Button from '../components/Button'
import Card from '../components/Card'
import Heading from '../components/Heading'
import Column from '../components/Column'
import Link from '../components/Link'
import Logo from '../components/Logo'
import Text from '../components/Text'
import TextInput from '../components/TextInput'
import { signup } from '../lib/api'
import { mediaQueries, spacing } from '../tokens'
import provinciasFromJson from './Requirements/data/provincias.json'
import * as yup from 'yup'

import {
  strengthColor,
  strengthIndicator,
  strengthMessage,
} from '../util/password'
import { useUser } from '../context/userContext'
import { CountriesEnum } from '../util/validations'
import Select from '../components/Select'

interface Props {
  [propName: string]: any
}

interface User {
  name: string
  email: string
  telefono: string
  pais: LocalizationType | null
  provincia: LocalizationType | null
  ciudad: LocalizationType | null
  password: string
  confirmPassword: string
}

interface ErrorsType {
  [propName: string]: string
}

interface LocalizationType {
  _id: string
  nombre: string
}

interface OtherLocalization {
  otherCountry: string
  otherCity: string
}

const countries = [
  { _id: CountriesEnum.ECUADOR, nombre: CountriesEnum.ECUADOR },
  { _id: CountriesEnum.OTRO, nombre: CountriesEnum.OTRO },
]

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const nameRegExp = /^([a-zA-ZñÑ\u0080-\u00ff]{2,}\s[a-zA-ZñÑ\u0080-\u00ff]{1,}'?-?[a-zA-ZñÑ\u0080-\u00ff]{2,}\s?([a-zA-ZñÑ\u0080-\u00ff]{1,})?)+$/i
const countryNameRegex = /[A-Za-zñÑ\u0080-\u00ff]/g

export const signupSchema = yup.object().shape({
  name: yup
    .string()
    .required('Campo requerido')
    .matches(nameRegExp, 'Nombre inválido'),
  email: yup
    .string()
    .required('Campo requerido')
    .email('Email válido'),
  telefono: yup
    .string()
    .matches(phoneRegExp, 'Número de teléfono inválido')
    .required('Campo requerido'),
  pais: yup
    .string()
    .matches(countryNameRegex, 'Nombre inválido')
    .required('Campo requerido')
    .nullable(),
  provincia: yup
    .string()
    .when(['pais'], {
      is: (pais: string) => {
        return pais === CountriesEnum.ECUADOR
      },
      then: yup
        .string()
        .required('Campo requerido')
        .nullable(),
    })
    .nullable(),
  ciudad: yup
    .string()
    .required('Campo requerido')
    .nullable(),
  password: yup
    .string()
    .required('Campo requerido')
    .nullable(),
  confirmPassword: yup
    .string()
    .required('Campo requerido')
    .nullable(),
})

function SignupComponent({ location }: Props) {
  const { user, reloadUser } = useUser()

  const [credentials, setCredentials] = useState<User>({
    name: '',
    email: '',
    telefono: '',
    pais: null,
    provincia: null,
    ciudad: null,
    password: '',
    confirmPassword: '',
  })
  const [passwordMatch, setPasswordMatch] = useState<boolean>(true)
  const [strengthPassword, setStrengthPassword] = useState<any>(null)
  const [provincesJson] = useState<Record<string, string[]>>(provinciasFromJson)
  const [provinces, setProvinces] = useState<LocalizationType[]>([])
  const [cities, setCities] = useState<LocalizationType[]>([])
  const [otherLocalization, setOtherLocalization] = useState<OtherLocalization>(
    {
      otherCountry: '',
      otherCity: '',
    },
  )
  const [errors, setErrors] = useState<ErrorsType | null>(null)

  const { error, isLoading, run } = useAsync({
    deferFn: args => signup(args[0], args[1]),
    onResolve: () => reloadUser(),
  })

  useEffect(() => {
    setProvinces(
      Object.keys(provincesJson).map(province => {
        return { _id: province, nombre: province }
      }),
    )
  }, [])
  useEffect(() => {
    const provinceCities = provincesJson[`${credentials.provincia?._id}`]
    if (provinceCities) {
      setCities(
        provinceCities.map(city => {
          return { _id: city, nombre: city }
        }),
      )
    } else {
      setCities([])
    }
  }, [credentials.provincia])

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (!passwordMatch) {
      return
    }

    let localization = {}
    if (isOtherCountry()) {
      localization = {
        pais: otherLocalization.otherCountry,
        provincia: null,
        ciudad: otherLocalization.otherCity,
      }
    } else {
      localization = {
        pais: credentials?.pais ? credentials.pais.nombre : null,
        provincia: credentials?.provincia ? credentials.provincia.nombre : null,
        ciudad: credentials?.ciudad ? credentials.ciudad.nombre : null,
      }
    }

    const body = {
      ...credentials,
      ...localization,
    }

    signupSchema
      .validate(body, { abortEarly: false })
      .then(function() {
        run(body)
      })
      .catch(function(err) {
        const validationErrors: ErrorsType = {}
        if (err.inner) {
          err.inner.forEach((error: any) => {
            if (error.path) {
              validationErrors[error.path] = error.message
            }
          })
        }
        setErrors(validationErrors)
      })
  }

  function handleChange(value: string, id: string) {
    setCredentials({
      ...credentials,
      [id]: value,
    })

    if (id === 'password') {
      setPasswordMatch(value === credentials.confirmPassword)
      setStrengthPassword(strengthIndicator(value))
    } else if (id === 'confirmPassword') {
      setPasswordMatch(value === credentials.password)
    }
  }

  function handleLocalization(selectedOption: LocalizationType, action: any) {
    if (action.name === 'pais') {
      setCredentials({
        ...credentials,
        ciudad: null,
        provincia: null,
        pais: selectedOption,
      })
    } else if (action.name === 'provincia') {
      setCredentials({
        ...credentials,
        ciudad: null,
        provincia: selectedOption,
      })
    } else {
      setCredentials({
        ...credentials,
        ciudad: selectedOption,
      })
    }
  }

  function handleOtherLocalization(value: string, id: string) {
    setOtherLocalization({
      ...otherLocalization,
      [id]: value,
    })
  }

  function isOtherCountry() {
    return credentials.pais && credentials.pais._id === CountriesEnum.OTRO
  }

  function getURLBeforeSingIn() {
    let URLTo = '/'
    if (location.state && location.state.from.pathname) {
      URLTo = location.state.from.pathname
    }
    return URLTo
  }

  if (user) {
    const URLTo = getURLBeforeSingIn()
    return <Redirect to={URLTo} />
  }

  return (
    <Column background>
      <Logo />
      <LoginWrapper>
        <Card alignCenter>
          <Card.Body paddingSize="large">
            <Heading>Crea una cuenta</Heading>
            <Text>¿Ya tienes una cuenta? </Text>
            <Link to={'/iniciar-sesion'}>Inicia sesión</Link>
            <WrapperForm>
              <form onSubmit={handleSubmit}>
                <TextInput
                  label="Nombre Completo"
                  name="name"
                  onChange={handleChange}
                  value={credentials.name}
                  error={errors?.name}
                />
                <TextInput
                  label="Email"
                  name="email"
                  onChange={handleChange}
                  type="email"
                  value={credentials.email}
                  error={errors?.email}
                />
                <TextInput
                  label="Número de celular"
                  name="telefono"
                  onChange={handleChange}
                  type="text"
                  value={credentials.telefono}
                  error={errors?.telefono}
                />
                {countries ? (
                  <Select
                    value={credentials.pais as LocalizationType}
                    label="País"
                    name="pais"
                    options={countries}
                    onChange={handleLocalization}
                    optionValue={'_id'}
                    optionLabel={'nombre'}
                    error={errors?.pais}
                  />
                ) : null}
                {credentials.pais?._id === CountriesEnum.ECUADOR &&
                provinces ? (
                  <Select
                    value={credentials.provincia as LocalizationType}
                    label="Provincia"
                    name="provincia"
                    options={provinces}
                    onChange={handleLocalization}
                    optionValue={'_id'}
                    optionLabel={'nombre'}
                    error={errors?.provincia}
                  />
                ) : null}
                {credentials.pais?._id === CountriesEnum.ECUADOR &&
                provinces.length ? (
                  <Select
                    value={credentials.ciudad as LocalizationType}
                    label="Ciudad"
                    name="ciudad"
                    options={cities}
                    onChange={handleLocalization}
                    optionValue={'_id'}
                    optionLabel={'nombre'}
                    error={errors?.ciudad}
                  />
                ) : null}
                {isOtherCountry() ? (
                  <WrapperTwoColumns>
                    <div>
                      <TextInput
                        label="Ingresa tu País"
                        name="otherCountry"
                        onChange={handleOtherLocalization}
                        value={otherLocalization.otherCountry}
                        error={errors?.pais}
                      />
                    </div>
                    <div>
                      <TextInput
                        label="Ingresa tu Ciudad"
                        name="otherCity"
                        onChange={handleOtherLocalization}
                        value={otherLocalization.otherCity}
                        error={errors?.ciudad}
                      />
                    </div>
                  </WrapperTwoColumns>
                ) : null}
                <TextInput
                  label="Contraseña"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={credentials.password}
                  error={errors?.password}
                />
                {strengthPassword !== null && credentials.password !== '' && (
                  <MessageContainer>
                    <Text status={strengthColor(strengthPassword)}>
                      {strengthMessage(strengthPassword) || ''}
                    </Text>
                  </MessageContainer>
                )}
                <TextInput
                  label="Confirmar Contraseña"
                  name="confirmPassword"
                  onChange={handleChange}
                  type="password"
                  value={credentials.confirmPassword}
                  error={errors?.confirmPassword}
                />
                {!passwordMatch && credentials.confirmPassword && (
                  <MessageContainer>
                    <Text status="danger">Las contraseñas no coinciden</Text>
                  </MessageContainer>
                )}
                {error && (
                  <MessageContainer>
                    <Text status="danger">{error.message}</Text>
                  </MessageContainer>
                )}
                <WrapperSubmitButton>
                  <Button disabled={isLoading || !passwordMatch} type="submit">
                    Crear Cuenta
                  </Button>
                </WrapperSubmitButton>
              </form>
            </WrapperForm>
            <p>
              <Text>Al registrarte confirmas que aceptas los </Text>
              <Link href="https://alau.org/terminos-y-condiciones">
                términos y condiciones
              </Link>
              <Text> de alaU.</Text>
            </p>
          </Card.Body>
        </Card>
      </LoginWrapper>
    </Column>
  )
}

const LoginWrapper = styled.div`
  width: 420px;
  margin-top: ${spacing.large};

  @media ${mediaQueries.xsmallMax} {
    width: 100%;
  }
`

const MessageContainer = styled.div`
  margin-bottom: ${spacing.small};
`

const WrapperForm = styled.div`
  margin: 15px 0px;
  text-align: left;
`

const WrapperSubmitButton = styled.div`
  text-align: center;
`

const WrapperTwoColumns = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    width: 100%;
    &:first-child {
      margin-right: ${spacing.small};
    }
  }
  @media ${mediaQueries.smallMax} {
    flex-direction: column;
  }
`

export default SignupComponent
