export interface Lesson {
  _id: string
  name: string
  resumen: string
  description: string
  category: string
  link: string
  free: boolean
  url: string
  image: string
  duration: string
  completed: boolean
  isPonderable: boolean
  quiz: Quiz
}

export interface Quiz {
  pro: boolean
  name: string
  slug: string
  duration: number
  questions: number
  startAt: string
  correct: number
  total: number
  approved: boolean
  gradeToApproved: number
}

export interface Practice {
  correct: number
  questions: number
  startAt: string
  enabled: boolean
}

export interface CourseFromApi {
  _id: string
  title: string
  slug: string
  hasPrice: boolean
  urlWebSite: string
  hasRequirements: boolean
  hasFinalQuiz: boolean
  finalQuizCategory: string
  showFinalQuiz: boolean
  finalQuiz: string
  filledRequirements: boolean
  requirements: object
  contents: ContentListItem[]
  froalaSignature: {}
}

export interface CourseDetailRequirements {
  image: string
  title: string
  description: string
}

export interface CourseRequirements {
  _id: string
  title: string
  detailRequirements: CourseDetailRequirements
}

export interface ContentWithLessons {
  _id: string
  title: string
  lessons: Lesson[]
  practice: Practice
  isFetching?: boolean
}

export interface CourseWithContents {
  id: string
  title: string
  slug: string
  contents: ContentListItem[]
  currentContent: string
  hasFinalQuiz: boolean
  finalQuiz: string
  urlWebSite: string
  filledRequirements: boolean
}

export interface ContentListItem {
  _id: string
  title: string
  percentage: number
  lessons: number
  progress: number
  completed?: boolean
}

export interface SignUpCredentials {
  name: string
  email: string
  password: string
  telefono: string
  pais: string
  provincia: string
  ciudad: string
}

export function formatCredentialsToSendAPI(credentials: SignUpCredentials) {
  const credentialsSpanish = {
    nombre: credentials.name,
    email: credentials.email,
    password: credentials.password,
    telefono: credentials.telefono,
    pais: credentials.pais,
    provincia: credentials.provincia,
    ciudad: credentials.ciudad,
  }
  return credentialsSpanish
}
