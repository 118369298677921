/* eslint-disable @typescript-eslint/camelcase */
import * as yup from 'yup'
import { validateDNI } from '../util/yup'

export const CountriesEnum = {
  ECUADOR: 'Ecuador',
  OTRO: 'Otro',
}

export const validarCedula = cedula => {
  if (
    typeof cedula === 'string' &&
    cedula.length === 10 &&
    /^\d+$/.test(cedula)
  ) {
    let digitos = cedula.split('').map(Number)
    let codigo_provincia = digitos[0] * 10 + digitos[1]
    if (
      ((codigo_provincia >= 1 && codigo_provincia <= 24) ||
        codigo_provincia === 30) &&
      digitos[2] < 6
    ) {
      let digito_verificador = digitos.pop()
      let digito_calculado =
        digitos.reduce((valorPrevio, valorActual, indice) => {
          return (
            valorPrevio -
            ((valorActual * (2 - (indice % 2))) % 9) -
            (valorActual === 9) * 9
          )
        }, 1000) % 10
      return digito_calculado === digito_verificador
    }
  }
  return false
}

export const validarRUC = ruc => {
  if (typeof ruc === 'string' && ruc.length === 13 && /^\d+$/.test(ruc)) {
    let digitos = ruc.split('').map(Number)
    let codigo_provincia = digitos[0] * 10 + digitos[1]
    let tipo_modulo
    let coeficientes
    if (
      ((codigo_provincia >= 1 && codigo_provincia <= 24) ||
        codigo_provincia === 30) &&
      (digitos[2] !== 7 || digitos[2] !== 8)
    ) {
      tipo_modulo = digitos[2] > 5 ? 11 : 10
      if (digitos[2] === 6) {
        digitos = digitos.slice(0, 9)
        coeficientes = [3, 2, 7, 6, 5, 4, 3, 2]
      } else {
        digitos = digitos.slice(0, 10)
        coeficientes =
          digitos[2] === 9
            ? [4, 3, 2, 7, 6, 5, 4, 3, 2]
            : [2, 1, 2, 1, 2, 1, 2, 1, 2]
      }
      let digito_verificador = digitos.pop()
      let digito_calculado =
        tipo_modulo -
        (digitos.reduce((valorPrevio, valorActual, indice) => {
          let resultado = valorActual * coeficientes[indice]
          if (digitos[2] < 6) {
            resultado = resultado > 9 ? resultado - 9 : resultado
          }
          return valorPrevio + resultado
        }, 0) %
          tipo_modulo)
      digito_calculado = digito_calculado === 11 ? 0 : digito_calculado
      return digito_calculado === digito_verificador
    }
  }
  return false
}

export const isValidDNI = numeroIdentificacion => {
  if (
    typeof numeroIdentificacion === 'string' &&
    numeroIdentificacion.length === 10 &&
    /^\d+$/.test(numeroIdentificacion)
  ) {
    let digitos = numeroIdentificacion.split('').map(Number)
    let codigo_provincia = digitos[0] * 10 + digitos[1]
    if (
      ((codigo_provincia >= 1 && codigo_provincia <= 24) ||
        codigo_provincia === 30) &&
      digitos[2] < 6
    ) {
      let digito_verificador = digitos.pop()
      let digito_calculado =
        digitos.reduce((valorPrevio, valorActual, indice) => {
          let multiplyer = 0
          if (valorActual === 9) {
            multiplyer = 1
          }
          return (
            valorPrevio -
            ((valorActual * (2 - (indice % 2))) % 9) -
            multiplyer * 9
          )
        }, 1000) % 10
      if (digito_calculado === digito_verificador) {
        return true
      }
      return false
    }
  }
  return false
}

export const validateName = name => {
  var regex = /^[a-zA-Z ]{5,30}$/
  return regex.test(name)
}

export const validatePhoneNumber = phoneNumber => {
  var regex = /^[0-9\-+]{10}$/
  return regex.test(phoneNumber)
}

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const nameRegExp = /^([a-zA-ZñÑ\u0080-\u00ff]{2,}\s[a-zA-ZñÑ\u0080-\u00ff]{1,}'?-?[a-zA-ZñÑ\u0080-\u00ff]{2,}\s?([a-zA-ZñÑ\u0080-\u00ff]{1,})?)+$/i
const countryNameRegex = /[A-Za-zñÑ\u0080-\u00ff]/g

yup.addMethod(yup.string, 'validateDNI', validateDNI)
export const requirementsSchema = yup.object().shape({
  documentoIdentificacion: yup
    .string()
    .when(['pais'], {
      is: pais => {
        return pais === CountriesEnum.ECUADOR
      },
      then: yup
        .string()
        .validateDNI()
        .nullable(),
    })
    .required('Campo requerido'),
  nombre: yup
    .string()
    .required('Campo requerido')
    .matches(nameRegExp, 'Nombre inválido'),
  email: yup
    .string()
    .required('Campo requerido')
    .email('Email válido'),
  sexo: yup
    .string()
    .required('Campo requerido')
    .nullable(),
  telefono: yup
    .string()
    .matches(phoneRegExp, 'Número de teléfono inválido')
    .required('Campo requerido'),
  edad: yup
    .number('Campo requerido')
    .positive('Le edad debe ser un número positivo')
    .required('Campo requerido'),
  pais: yup
    .string()
    .matches(countryNameRegex, 'Nombre inválido')
    .required('Campo requerido')
    .nullable(),
  provincia: yup
    .string()
    .when(['pais'], {
      is: pais => {
        return pais === CountriesEnum.ECUADOR
      },
      then: yup
        .string()
        .required('Campo requerido')
        .nullable(),
    })
    .nullable(),
  ciudad: yup
    .string()
    .required('Campo requerido')
    .nullable(),
  etnia: yup
    .string()
    .required('Campo requerido')
    .nullable(),
  nivelInstruccion: yup
    .string()
    .required('Campo requerido')
    .nullable(),
  ocupacion: yup
    .string()
    .required('Campo requerido')
    .nullable(),
  actividadEconomica: yup.string().when(['ocupacion'], {
    is: ocupacion => {
      return ocupacion === 'Independiente / emprendedor'
    },
    then: yup
      .string()
      .nullable()
      .required('Campo requerido'),
  }),
  terms: yup
    .boolean()
    .oneOf([true], 'Debes aceptar los términos y condiciones'),
})
